import { PageRendererProps } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import consultImage from '../../assets/static/consult/consult4.jpg'
import case1ImageDesktop from '../../assets/static/service/case1_desktop.png'
import case1ImageMobile from '../../assets/static/service/case1_mobile.png'
import case2ImageDesktop from '../../assets/static/service/case2_desktop.png'
import case2ImageMobile from '../../assets/static/service/case2_mobile.png'
import Indications1 from '../../assets/static/service/Indications1.png'
import Indications2 from '../../assets/static/service/Indications2.png'
import Indications3 from '../../assets/static/service/Indications3.png'
import veneers from '../../assets/static/service/veneers.jpg'
import ServiceLayout from '../../components/Layout/ServiceLayout'
import Seo from '../../components/Seo'
import { ContentTitle } from '../../components/Titles/ContentTitle'
import { space } from '../../constants/length'
import { viewport } from '../../constants/viewport'
import Collapse from './components/Collapse'
import ContentSection from './components/ContentSection'
import ListItem from './components/ListItem'

const StyledContentTitle = styled(ContentTitle)`
  margin-bottom: ${space.l}px;
`
const CompareImages = styled.div`
  img + img {
    margin-top: ${space.m}px;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  margin-right: ${space.m}px;

  @media (max-width: ${viewport.tablet - 1}px) {
    margin-bottom: ${space.m}px;
  }
`

const IndicationsImageWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: ${viewport.tablet - 1}px) {
    flex-direction: column;
  }
  & > ${ImageWrapper}:nth-child(3) {
    margin-right: 0;
  }
`

const IndicationsImage = styled.img``
const QuestionSection = styled.div`
  margin-top: ${space.l * 2}px;
`

const DesktopCaseImage = styled.img`
  @media (max-width: ${viewport.tablet - 1}px) {
    display: none;
  }
`
const MobileCaseImage = styled.img`
  display: none;
  @media (max-width: ${viewport.tablet - 1}px) {
    display: block;
  }
`

const ListGroup = styled.div`
  margin-top: ${space.m}px;
`
const VeneersText = styled.div`
  padding-top: 24px;
`

const Veneers = (props: PageRendererProps) => {
  const { formatMessage } = useIntl()

  const routes = [
    {
      label: formatMessage({ id: 'breadcrumb.index' }),
      path: '/'
    },
    {
      label: formatMessage({ id: 'service.index' }),
      path: '/service/digital'
    },
    {
      label: formatMessage({ id: 'service.veneers' }),
      path: '/service/veneers'
    }
  ]

  return (
    <ServiceLayout routes={routes} consultImage={consultImage} {...props} pageBannerInfo={formatMessage({ id: 'banner.veneers' })}>
      <Seo
        title='【陶瓷貼片14萬9】打造完美笑容的快速捷徑-日不落陶瓷貼片專家'
        metaDescription='14萬9就能擁有名人般的迷人微笑？交給遍布台北、新竹、台中、台南的日不落陶瓷貼片專家完成您的願望！依照您要求的『形與色』設計，搭配陶瓷貼片或全瓷冠兩種服務，給你閃亮潔白的齒色與黃金比例的外型。'
      />
      <StyledContentTitle>全瓷貼片/{formatMessage({ id: 'service.veneers' })}</StyledContentTitle>
      <ContentSection id='patch' imageUrl={veneers} imagePosition='fluid' imageAlt='全瓷貼片/美白陶瓷貼片'>
        <VeneersText>
          <p>
            陶瓷貼片就像『水晶指甲』，直接在牙齒表面貼上美麗的陶瓷齒面，是您打造完美笑容的快速捷徑。依照您要求的齒形與齒色、以及牙齒現況排列位置，決定您牙齒修磨量的多寡，若需要較大色差、縮小外形、前後參差，則修磨量就相對較大、甚至須考慮製作全瓷牙冠。
          </p>
          <br />
          <p>
            日亞美推出了<strong>『陶瓷貼片專案14萬9』</strong> ，專注於<strong>上排最重要的微笑曲線8顆，並包含DSD數位微笑設計費用</strong>
            。或是您對美學要求較高、亦可製作上下排共12~16顆，以打造最完美的微笑曲線。
          </p>
        </VeneersText>
      </ContentSection>
      <ContentSection title='誰適合全瓷貼片/美白陶瓷貼片？'>
        <IndicationsImageWrapper>
          <ImageWrapper>
            <IndicationsImage src={Indications1} alt='陶瓷貼片範例1' />
          </ImageWrapper>
          <ImageWrapper>
            <IndicationsImage src={Indications2} alt='陶瓷貼片範例2' />
          </ImageWrapper>
          <ImageWrapper>
            <IndicationsImage src={Indications3} alt='陶瓷貼片範例3' />
          </ImageWrapper>
        </IndicationsImageWrapper>
        <ListGroup>
          <ListItem content='同時想修整門牙齒色、比例'>
            <ListItem.ColorfulContent content='門牙齒縫' />
          </ListItem>
          <ListItem content='透光度差、色澤度差'>
            <ListItem.ColorfulContent content='傳統金屬內冠假牙' />
          </ListItem>
          <ListItem content='樹酯色澤與真牙不一致'>
            <ListItem.ColorfulContent content='門牙蛀牙填補' />
          </ListItem>
        </ListGroup>
      </ContentSection>
      <ContentSection title='全瓷貼片/美白陶瓷案例分享'>
        <CompareImages>
          <DesktopCaseImage src={case1ImageDesktop} alt='前牙蛀牙/變色' />
          <DesktopCaseImage src={case2ImageDesktop} alt='小兔牙（輕微凌亂）' />
          <MobileCaseImage src={case1ImageMobile} alt='前牙蛀牙/變色' />
          <MobileCaseImage src={case2ImageMobile} alt='小兔牙（輕微凌亂）' />
        </CompareImages>
      </ContentSection>
      <QuestionSection>
        <ContentSection title='全瓷貼片/美白陶瓷 常見問題'>
          <Collapse title='為什麼需要做陶瓷貼片?'>
            <p>1. 變色的牙齒 (四環黴素或其他藥物影響導致牙齒變色)</p>
            <p>2. 缺角的牙齒、自覺牙齒太大or太小</p>
            <p>3. 輕微牙齒不整齊、齒間有縫隙</p>
            <p>4. 永久牙齒美白</p>
          </Collapse>
          <Collapse title='做陶瓷貼片可以不磨牙嗎?'>
            <p>最近牙科常見的免磨牙貼片，常常會有暴牙、牙齦發炎等問題產生，且因空間有限，瓷貼片太薄較容易破損。只有少數情況 (本身牙齒就很小、且縫隙很多) 才不用磨牙。</p>
            <p>
              請注意：『非牙科診所』宣稱的免磨牙貼片並非陶瓷材質，通常為複合式樹酯。且瓷貼片黏附牙齒的黏著劑需要專業醫師操作，才可確保接縫處密封無縫，否則極度容易造成貼片底下的真牙蛀蝕潰爛，請勿貪小便宜而毀了自己寶貴的牙齒。
            </p>
          </Collapse>
          <Collapse title='陶瓷貼片要多少錢?'>
            <p>前牙8顆瓷貼片14萬9(含DSD數位微笑設計)，但因每個人笑容方式不同，建議直接透過電話或是使用 LINE @，讓專業諮詢師為您服務、打造您專屬的微笑曲線。</p>
          </Collapse>
        </ContentSection>
      </QuestionSection>
    </ServiceLayout>
  )
}

export default Veneers
